:root {
  --primary-color: #171c1f;
  --overlay: rgba(0,0,0,0.3);
  --secondary-color: #aaa;
  --white-font: #ffffff;
  --grey-font: #b3b3b3;

  /* XS DEVICES */
  --title-xs-device: 24px;
  --subtitle-xs-device: 16px;
  --bq-xs-device: 16px;
  --text-xs-device: 13px;
  --height-btn-xs: 45px;
  --width-btn-xs: 160px;

  /* S DeVICES */
  --title-s-device: 28px;
  --subtitle-s-device: 16px;
  --bq-s-device: 18px;
  --text-s-device: 14px;
  --height-btn-xs: 45px;
  --width-btn-xs: 160px;

  /* L DEVICES */
  --text-l-device: 16px;
  --title-l-device:35px;
  --subtitle-l-device: 20px;
  --bq-l-device: 18px;
  --height-btn-l: 50px;
  --width-btn-l: 160px;

  /* XL DEVICES */
  --text-xl-device: 18px;
  --title-xl-device:45px;
  --subtitle-xl-device: 24px;
  --bq-xl-device: 20px;
  --height-btn-xl: 60px;
  --width-btn-xl: 180px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  min-width: 300px;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color);
  font-size: 16px;
}

code {
  font-family: 'Open sans', 'Helvetica Neue', sans-serif;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: #fff;
}

.button {
  padding: 17px 20px;
  font-size: 1.2rem;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  background-color: var(--primary-color);
  border: 1px solid #fff;
  border-radius: 4px;
  cursor: pointer;
}

.button:hover {
  background: var(--secondary-color);
  border: 1px solid var(#fff);
}

.line {
  display: block;
  width: 40px;
  height: 3px;
  margin: 5px auto;
  background-color: #fff;
}

.leaflet-container {
  width: 100%;
  height: 50vh;
}

    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
    
    }
    
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
    
    }
    
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
    
    }
    
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        
    }
    
    /* Extra large devices (large laptops and desktops, 1200px and up) */
    @media only screen and (min-width: 1200px) {
    
    }